var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',[(_vm.isInFullScreen)?_c('text',{staticClass:"scheme-text",attrs:{"x":"400","y":_vm.figures.textY}},[_vm._v(" № "+_vm._s(_vm.currentNumber)+" | "),_c('tspan',{attrs:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.item.material.name)+" ")]),_vm._v(" | "+_vm._s(_vm.item.material.value)+" "+_vm._s(_vm.$t('message.units.mm'))+" ")]):_vm._e(),_c('pattern',{attrs:{"id":_vm.bgId,"width":"1","height":"1"}},[_c('image',{attrs:{"href":_vm.image.url,"x":"0","y":"0","width":"336","transform":("scale(1 " + _vm.coefficient + ")")}})]),_c('path',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      followCursor: false,
      animation: 'shift-toward',
      theme: 'light',
      delay: 100,
      arrow: !_vm.isInMobileModal,
      placement: _vm.isInMobileModal ? 'top bottom' : 'left'
    }),expression:"{\n      followCursor: false,\n      animation: 'shift-toward',\n      theme: 'light',\n      delay: 100,\n      arrow: !isInMobileModal,\n      placement: isInMobileModal ? 'top bottom' : 'left'\n    }"}],staticClass:"hexagon__image",attrs:{"d":_vm.figures.hexagon,"content":("№ " + _vm.currentNumber + " | <span class='hexagon__tooltip'><strong>" + (_vm.item.material.name) + "</strong><span class='hexagon__tooltip-info'> | " + (_vm.item.material.value) + (_vm.$t(
      'message.units.mm'
    )) + "</span></span>"),"fill":("url(#" + _vm.bgId + ")")}}),(_vm.isLayerHasFastener)?_vm._t("fastener"):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }