<template>
  <div class="search-loading">
    <loading
      :active.sync="isLoadingShow"
      v-if="isLoadingShow"
      :can-cancel="false"
      color="#E30713"
      :height="60"
      :width="60"
      :opacity="1"
      backgroundColor="transparent"
      :z-index="3"
      :is-full-page="false"
    ></loading>
  </div>
</template>

<script>
export default {
  props: {
    isLoadingShow: {
      type: Boolean
    }
  },
  name: 'SearchLoading'
}
</script>

<style scoped lang="sass">
.search-loading
  position: relative
  // margin: rem(16) 0
  .vld-overlay
    position: relative
    line-height: 0
</style>
