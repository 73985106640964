<template>
  <section class="elements elements_multilayer">
    <header class="elements__left elements__left--full">
      <div class="elements__left-info">
        <span class="name">{{ group.name }}</span>
        <div class="material">
          <multilayer-toggle @showMultilayer="showMultilayer"></multilayer-toggle>
          <span>{{ getGroupMaterialName() }}</span>
        </div>
      </div>
    </header>
    <template v-if="isShow">
      <div class="elements__left--full elements__left-multilayer" v-for="(material, m) in multiLayerMaterials()" :key="m">
        <div class="name">
          <span>{{ material.name }}</span>
        </div>
        <Input-or-select
            :item="material"
            :parent-type="'multilayer'"
            :isInCustom="true"
            :layerIndex="layerIndex"
            :itemIndex="itemIndex"
            :multilayerIndex="m"
        />
        <CircleMenu
          @clickedFromCircleMenu="childLayerMenuAction($event, m)"
          :setUp="getButtonsSetup(multiLayerMaterials.length, m, material)"
        >
          <template #icon>
            <img src="@/assets/img/menu.svg" alt="" />
          </template>
        </CircleMenu>
      </div>
    </template>
    <button class="single-layer__body-add" @click="request">
      <span class="text">{{ $t('message.add.item') }}</span>
    </button>
  </section>
</template>

<script>
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
import CircleMenu from '@/components/elements/Dom/CircleMenu'
import { getButtonsSetup } from '@/utils/customFunctions'
import { mapActions } from 'vuex'
import MultilayerToggle from '@/components/smart/System/Multilayers/MultilayerToggle'

export default {
  name: 'Multilayer',
  props: {
    group: Object,
    sector: Object,
    sectorIndex: Number,
    layerIndex: Number,
    itemIndex: Number
  },
  components: {
    MultilayerToggle,
    InputOrSelect,
    CircleMenu
  },
  data() {
    return {
      isShow: true
    }
  },
  methods: {
    ...mapActions({
      removeMultilayerItem: 'removeMultilayerItem'
    }),
    getGroupMaterialName() {
      return this.$t('message.system.multilayer.commonGroupTitle')
    },
    request() {
      this.$emit('getAlternativeMaterials')
    },
    multiLayerMaterials() {
      return this.$store.getters.getSector(this.sectorIndex).layers[this.layerIndex].items[this.itemIndex].materials || []
    },
    getButtonsSetup(itemsLength, index, item) {
      return getButtonsSetup(itemsLength, index, item, '')
    },
    childLayerMenuAction(evt, index) {
      this.removeMultilayerItem({
        sectorIndex: this.sectorIndex,
        layerIndex: this.layerIndex,
        itemIndex: this.itemIndex,
        index
      })
    },
    showMultilayer() {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped lang="sass">
.single-system__layers
  .single-layer
    &__body
      .elements
        &.elements_multilayer
          padding-bottom: 0
          .elements__left--full
            padding: 0 0 rem(15)
            box-shadow: inset 0 -0.0625rem 0 #ececec
            &.elements__left-multilayer
              display: grid
              padding: rem(15) 0
              position: relative
              +media((grid-template-columns: (320: 22fr rem(16) 6fr)))
              .name
                display: flex
                align-items: center
                @extend .fs-12
                padding: 0 rem(15) 0 0
          .elements__left-info
            .name
              display: flex
              padding-bottom: rem(10)
            .material
              display: flex
              align-items: center
              @extend .fs-14
              span
                user-select: none
          .single-layer__body-add
            @extend .clear-btn
            @extend .fs-14
            display: flex
            flex-direction: row
            align-items: center
            font-weight: bold
            background: url("~@/assets/img/icons/deep-settings/add.svg") 0 center no-repeat
            width: 100%
            height: rem(56)
            text-align: left
            padding: 0 rem(20) 0 rem(40)
</style>
