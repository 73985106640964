<script>
import { getButtonsSetup } from '@/utils/customFunctions'
import { mapActions } from 'vuex'

export default {
  name: 'multilayerMixin',
  data: () => ({
    multilayerItems: [],
    isMultilayerModeOn: false
  }),
  methods: {
    ...mapActions({
      deleteMultilayerItems: 'deleteMultilayerItems'
    }),
    editMultilayerItems(parentIndex, itemIndex, id) {
      if (this.multilayerItems.includes(id)) {
        const index = this.multilayerItems.findIndex((i) => i === id)
        this.multilayerItems.splice(index, 1)

        this.deleteMultilayerItems({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex
        })
      } else {
        this.multilayerItems.push(id)
      }

      this.isMultilayerModeOn = Boolean(this.multilayerItems.length)
    },
    getButtonsSetup(itemsLength, index, item, layerName) {
      return getButtonsSetup(itemsLength, index, item, 'system', this.canAddMultilayer(layerName, item))
    },
    isMultilayerItem(layerName, item) {
      return this.canAddMultilayer(layerName, item) && this.multilayerItems.includes(item.id)
    },
    isRoofingCarpetGroup(layerName, item) {
      if (this.layersList) {
        return this.layersList.find((layer) => layer.name === layerName)?.items.find((i) => i.id === item.id && i.additionalProducts) || null
      }
      return null
    },
    canAddMultilayer(layerName, item) {
      return this.isRoofingCarpetGroup(layerName, item) &&
          item.material.isMechanicalFasteners
    },
    isEditable(layerName, item, parentIndex, itemIndex, isCustom) {
      const canAddMultilayerItem = this.canAddMultilayer(layerName, item)
      const isInRoofingCarpetGroup = Boolean(this.isRoofingCarpetGroup(layerName, item))

      if (isCustom) {
        return !((!canAddMultilayerItem && isInRoofingCarpetGroup))
      } else {
        return isInRoofingCarpetGroup ? canAddMultilayerItem : false
      }
    }
  }
}
</script>
