<template>
  <div class="fastener-info">
    <div class="fastener-info__head">
      <div class="items">
        <Fastener-toggler
          :id="id"
          :currentChecked="currentChecked"
          @showFastenerInfo="showFastenerInfo"
        />
        <div
          class="fastener-name"
          @click="showChangeFastenerModal"
          :class="{
            'fastener-name--disabled': !isFastenerSelectionEnabled,
            'fastener-name--wedge': isWedge
          }"
        >
          <div class="fastener-name__info">
            <div class="title">
              {{ $t('message.fastenerInfo.title') }}
            </div>
            <div class="name">{{ currentFastenerName }}</div>
          </div>
          <div class="chevron">
            <img class="" :src="chevronSrc" alt="↓" />
          </div>
        </div>
        <div class="rate" v-if="isShowRateInput">
          <template v-if="!isInCustom">
            <tippy interactive trigger="mouseenter" theme="left" class="fastener__tippy">
              <template v-slot:trigger>
                <img src="@/assets/img/icons/info.svg" alt="i">
              </template>
              <i18n :path="fastenerInfoHint">
              </i18n>
            </tippy>
          </template>
          <div class="layer--input-with-units">
            <input type="number" v-model="computedRate" />
            <span class="units">{{ $t('message.units.pc') }}</span>
          </div>
        </div>
        <div class="rate" v-if="isShowRatePlug">
          <div class="layer--input-with-units layer--input-with-units--disabled">
            <div class="value">{{ computedRate }}</div>
            <span class="units">{{ $t('message.units.pc') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="additional" v-if="currentChecked === id">
      <div class="fastener-info__base" :class="{ 'fastener-info__base--wedge': isWedge }">
        <i18n path="message.fastenerInfo.base" tag="div" class="fastener-info__base-text">
          <template #break>
            <br />
          </template>
        </i18n>
        <div class="layer-select layer-select--disabled">
          <div class="value">{{ base }}</div>
        </div>
      </div>
      <div class="fastener-info__fastener" v-if="!isWedge">
        <div class="fastener-info__fastener-height">
          <i18n path="message.fastenerInfo.height" tag="div" class="text">
            <template #break>
              <br />
            </template>
          </i18n>
          <div
            class="layer--input-with-units layer--input-with-units--disabled layer--input-with-units--fastener"
          >
            <div class="value">{{ height }}</div>
            <span class="units">{{ $t('message.units.mm') }}</span>
          </div>
        </div>
      </div>
    </div>
    <Modal :isModalOpen="isModalOpen" :isShowClose="false" @close="close">
      <template #body>
        <Fastener-selection
          :fastenerId="fastenerId"
          :layerIndex="layerIndex"
          :itemIndex="itemIndex"
          :base-type-id="baseTypeId"
          placement="system"
          :is-in-custom="isInCustom"
          :available-fasteners="availableFasteners"
          @close="close"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Modal from '@/components/elements/Modals/Modal'
import FastenerSelection from '@/components/elements/Modals/FastenerSelection'
import FastenerToggler from '@/components/elements/Dom/Fastener-toggler'
export default {
  name: 'Fastener-info',
  props: {
    itemIndex: Number,
    layerIndex: Number,
    fastenerId: Number,
    base: String,
    height: Number,
    rate: Number,
    isShowCheckBox: Boolean,
    availableFasteners: Array,
    isInCustom: Boolean,
    baseTypeId: Number,
    isWedge: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    currentChecked: {
      type: String,
      required: true,
      default: ''
    },
    isRateMutable: {
      type: Boolean
    }
  },
  data: () => ({
    isModalOpen: false
  }),
  components: {
    FastenerToggler,
    Modal,
    FastenerSelection
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM: 'UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM',
      UPDATE_SECTOR_SYSTEM_LAYER_COMPUTED_FASTENER: 'UPDATE_SECTOR_SYSTEM_LAYER_COMPUTED_FASTENER'
    }),
    close() {
      this.isModalOpen = false
    },
    showChangeFastenerModal() {
      if (this.isInCustom) {
        this.isModalOpen = true
      }
      if (!this.isInCustom && this.availableFasteners?.length > 1) {
        this.isModalOpen = true
      }
    },
    showFastenerInfo(id) {
      this.$emit('showFastenerInfo', id)
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners
    }),
    fastenerInfoHint() {
      const disclaimers = JSON.parse(localStorage.getItem('disclaimer'))

      return disclaimers ? disclaimers.fasteners.count : ''
    },
    isShowRateInput() {
      return this.isInCustom ? !this.isWedge : this.isRateMutable && !this.isWedge
    },
    isShowRatePlug() {
      return this.isWedge ? false : !this.isRateMutable && !this.isInCustom
    },
    chevronSrc() {
      return this.isFastenerSelectionEnabled
        ? require('@/assets/img/icons/filter--select.svg')
        : require('@/assets/img/icons/filter--select--disabled.svg')
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentFastenerName() {
      return this.allFasteners.find(p => p.id === this.fastenerId).name
    },
    isFastenerSelectionEnabled() {
      if (this.isInCustom) {
        return true
      } else {
        return this.availableFasteners?.length > 1
      }
    },
    computedRate: {
      get() {
        return this.rate
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          this.UPDATE_SECTOR_SYSTEM_LAYER_COMPUTED_FASTENER({
            sectorIndex: this.sectorIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            value: parseFloat(value)
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~@/assets/sass/_vars.sass'
body
  .tippy__link
    color: $default
.fastener-info
  &__head
    display: grid
    grid-column: 3 / 5
    .items
      padding: 0 0  rem(15) // [*]
      display: grid
      $t-l: 2fr 16fr 1fr 5fr
      $t-m: rem(40) calc(((100% - 80px) / 19 * 18) - 40px) 1fr rem(80)
      $t-s: rem(30) calc(((100% - 80px) / 19 * 18) - 30px) 1fr rem(80)
      +media((grid-template-columns: (0: $t-s, 400: $t-m, 960: $t-l)))
    .rate
      display: flex
      align-items: center
      grid-column-start: 4
      justify-content: flex-end
    .fastener-name
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      box-shadow: inset rem(-1) 0 0 #ECECEC
      cursor: pointer
      &--wedge
        grid-column: 2 / 5
        box-shadow: none
      &__info
        $w-s: calc(100% - 32px)
        $w-l: calc(100% - 40px)
        +media((width: (0: $w-s, 960: $w-l)))
      &--disabled
        cursor: default
      .title
        @extend .fs-14
        +media((font-size: (320: rem(12), 400: rem(14))))
        font-weight: bold
        padding-bottom: rem(4)
      .name
        @extend .fs-12
        color: $light-black
      .chevron
        +media((width: (0: rem(32), 960: rem(40))))
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
  .additional
    display: grid
    $t-l: 2fr 22fr // [*]
    $t-m: rem(40) 1fr
    $t-s: rem(30) 1fr
    +media((grid-template-columns: (0: $t-s, 400: $t-m, 960: $t-l)))
    position: relative
    .fastener-info
      &__base, &__fastener
        grid-column-start: 2
        position: relative
      &__base
        &:before
          height: calc(100%)
          top: 0
          transform: translate(-200%, -50%)
      &__fastener
        &:before
          height: calc(100%)
          top: rem(0)
          transform: translate(-200%, calc((50% - 7.5px) * -1))
  &__base
    display: flex
    flex-direction: row
    align-items: center
    &--wedge
      margin-bottom: rem(16)
    &-text
      @extend .fs-12
      color: $light-black
      min-width: rem(80)
    .layer-select
      @extend .fs-12
      font-weight: bold
      @extend .disabled-element
      width: 100%
      padding-right: rem(32)
      +media((margin-left: (320: rem(8), 960: rem(20))))
      &:after
        content: ""
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: rem(12)
        height: rem(10)
        width: rem(10)
        background-image: url(~@/assets/img/select.svg)
        background-position: center
        background-repeat: no-repeat
        pointer-events: none
        background-size: contain
      &--disabled
        &:after
          background-image: url(~@/assets/img/icons/select--disabled.svg)
  &__fastener
    display: flex
    align-items: center
    justify-content: space-between
    +media((align-items: (320: flex-start, 400: center)))
    +media((flex-direction: (320: column, 400: row)))
    +media((padding: (320: rem(15) 0 0, 960: rem(15) 0 0)))
    +media((margin: (320: 0 0 rem(15), 960: 0 0 rem(25))))
    &-height
      .text
        min-width: rem(80)
      .layer--input-with-units
        +media((font-size: (320: rem(12), 960: rem(14))))
        +media((min-width: (320: rem(80), 960: rem(100))))
        max-width: unset
        width: auto
        .value
          box-sizing: border-box
    &-height
      display: flex
      align-items: center
      flex-direction: row
      .text
        +media((margin-right: (320: rem(8), 960: rem(20))))
        @extend .fs-12
        color: $light-black
.single-system__layers
  .single-layer
    &__body
      &-item
        &--extend
          .fastener-info
            grid-column: 1 / 5
            &__head
              grid-template-columns: 1fr
              .items
                .rate:has(img)
                  +media((min-width: (320: rem(80), 960: rem(100))))
                  img
                    width: 15px
                    margin-right: rem(6)
                  .layer--input-with-units
                    min-width: unset
                    width: auto
                    grid-template-columns: 55% 45%
                  .fastener__tippy + .layer--input-with-units
                    grid-template-columns: 62% 30%
                    input
                      padding-right: rem(6)
            .additional
              display: grid
</style>
