<template>
  <button class="fixed-trigger" @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit('fixedTriggerClicked')
    }
  }
}
</script>

<style scoped lang="sass">
.fixed-trigger
  z-index: 3
  @extend .clear-btn
  +media((display: (320: flex, 960: none)))
  position: fixed
  right: rem(24)
  bottom: rem(24)
  height: rem(56)
  width: rem(56)
  background: $green
  box-shadow: 0 0 0 rem(10) rgba(148, 227, 181, 0.3)
  border-radius: 50%
  flex-direction: row
  justify-content: center
  align-items: center
</style>
