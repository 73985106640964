<template>
  <div
    class="single-layer__body-add"
    :class="{ active: isShowList === layerIndex, disabled: disabled }"
    :content="$t('message.add.warning')"
    @show="() => disabled"
    v-tippy
  >
    <button @click="showList(layerIndex)" class="btn">
      <span class="icon">
        <img
          src="@/assets/img/icons/layer--add-close.svg"
          alt="❌"
          v-if="isShowList === layerIndex"
        />
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-else
        >
          <circle cx="10" cy="10" r="10" fill="#94E3B5"></circle>
          <path
            d="M10 14V6M14 10H6"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
      <span class="text"> {{ $t('message.add.item') }} </span>
    </button>
    <div class="list" v-if="isShowList === layerIndex">
      <materials-search
        :layer-id="layerId"
        :layer-index="layerIndex"
        :type="placement"
        v-if="!isStock"
        @hideList="hideList"
      />
      <div class="list__item" v-if="placement === 'junction'">
        <button @click="addFastenerLayer()" class="fastener-btn">
          {{ $t('message.fastenerInfo.title') }}
        </button>
      </div>
      <template v-if="isStock">
        <div
          class="list__item"
          v-for="layer in availableItems"
          :key="`layer-${layerIndex}-${layer.id}`"
        >
          <search-results-item :item="layer" @click="selectMaterial(layer.layer)" />
        </div>
      </template>
      <template v-else>
        <div class="list__item" v-for="layer in itemsList" :key="`layer-${layerIndex}-${layer.id}`">
          <input
            type="radio"
            :value="layer.id"
            :id="`layer-${layerIndex}-${layer.id}`"
            @change="selectLayer(layer)"
            class="input"
          />
          <label :for="`layer-${layerIndex}-${layer.id}`" class="label">{{ layer.name }}</label>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import MaterialsSearch from '@/components/smart/MaterialsSearch'
import SearchResultsItem from '@/components/smart/MaterialsSearch/SearchResultsItem'

export default {
  components: { MaterialsSearch, SearchResultsItem },
  props: {
    layerIndex: Number,
    sectorIndex: Number,
    layerId: Number,
    placement: String,
    custom: {
      type: Boolean,
      default: true
    },
    additionalItems: {
      type: Array,
      default: () => []
    },
    addedItems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isShowList: null
  }),
  methods: {
    ...mapMutations({
      ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH: 'ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH'
    }),
    showList(index) {
      this.isShowList = this.isShowList === index ? null : index
    },
    selectLayer(layer) {
      this.$emit('getNewLayer', layer)
      this.hideList()
    },
    selectMaterial(layer) {
      this.ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH({
        sectorIndex: this.sectorIndex,
        layerIndex: this.layerIndex,
        childLayer: layer
      })
      this.hideList()
    },
    addFastenerLayer() {
      this.$emit('addMountLayer', this.layerIndex)
      this.hideList()
    },
    hideList() {
      this.isShowList = false
    }
  },
  computed: {
    ...mapState({
      systemLayersList: state => state.layersList,
      junctionsLayersList: state => state.junctionsLayersList
    }),
    maxAvailable() {
      return this.availableItems?.length
    },
    availableItems() {
      let res = [...this.additionalItems]
      this.addedItems.forEach(el => {
        const itemIndex = res.findIndex(elItem => elItem.id === el.material.id)
        res.splice(itemIndex, 1)
      })
      return res
    },
    disabled() {
      return this.isInSystem && !this.custom ? this.maxAvailable === 0 : false
    },
    isStock() {
      return this.custom ? !!this.availableItems.length : !!this.availableItems
    },
    itemsListByStockKit() {
      return this.systemItemsList.filter(item => this.availableItems.includes(item.id))
    },
    systemItemsList() {
      return this.systemLayersList.find(p => p.id === this.layerId).items
    },
    itemsList() {
      if (this.isInSystem) {
        return this.isStock ? this.itemsListByStockKit : this.systemItemsList
      } else {
        return this.junctionsLayersList.find(p => p.id === this.layerId)?.items
      }
    },
    isInSystem() {
      return this.placement === 'system'
    }
  }
}
</script>

<style lang="sass" scoped>
.single-system__layers
  .single-layer
    &__body
      &-add
        &.active
          background: #fff
          box-shadow: $default-box-shadow
          border-radius: rem(4)
          .btn
            border-color: transparent
            background: #fff
            &:hover
              box-shadow: none
        &.disabled
          opacity: 0.5
          button
            pointer-events: none
        .btn
          @extend .clear-btn
          @extend .fs-14
          display: flex
          flex-direction: row
          align-items: center
          font-weight: bold
          background: #F2F2F2
          width: 100%
          height: rem(56)
          text-align: left
          padding: 0 rem(20)
          border: rem(1) dashed #D6D6D6
          border-radius: rem(4)
          @media(any-hover: hover)
            &:hover
              background: #ffffff
              border-color: transparent
              box-shadow: $default-box-shadow
              .icon
                circle
                  fill: $green
          .icon
            line-height: 0
            circle
              transition: .5s
          .text
            padding-left: rem(20)
        .list
          +media((padding: (0: rem(16) rem(20), 576: rem(16) rem(40))))
          width: 100%
          box-sizing: border-box
          // background: #fff
          &__item
            .input
              display: none
              pointer-events: none
              position: absolute
              opacity: 0
              &:checked
                + label
                  background-color: $red
                  background-image: url("~@/assets/img/icons/layer--select.svg")
                  font-weight: 700
                  color: #fff
            .label, .fastener-btn
              box-sizing: border-box
              cursor: pointer
              transition: .5s
              width: 100%
              display: flex
              flex-direction: row
              align-items: center
              padding: 0 rem(20)
              border-radius: rem(4)
              height: rem(48)
              background-repeat: no-repeat
              background-position: calc(100% - 18px) center
              @extend .fs-14
              &:hover
                background-color: $red
                background-image: url("~@/assets/img/icons/layer--select.svg")
                font-weight: 700
                color: #fff
            .fastener-btn
              @extend .clear-btn
</style>
