<template>
  <div class="search-box__hint">
    <img src="@/assets/img/icons/hint.svg" alt="i" class="icon" />
    {{ $t('message.system.search.hint') }}
  </div>
</template>

<script>
export default {
  name: 'Hint'
}
</script>

<style scoped lang="sass">
.search-box
  &__hint
    margin: rem(16) auto
    padding: 0 rem(20)
    $w-l: calc(100% - 40px)
    $w-s: 100%
    +media((width: (0: $w-s, 576: $w-l)))
    box-sizing: border-box
    @extend .fs-12
    color: $dark-grey
    display: flex
    align-items: center
    .icon
      margin-right: rem(12)
</style>
