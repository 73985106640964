<template>
  <div class="multilayer-toggler">
    <input
        type="checkbox"
        class="multilayer-toggler__input"
        id="multilayerToggle"
        :checked="isChecked"
        @change="showMultilayer"
    />
    <label for="multilayerToggle" class="multilayer-toggler__label">
      <inline-svg :src="require('@/assets/img/circleMenu/multilayer--icon.svg')" />
    </label>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  components: {
    InlineSvg
  },
  data() {
    return {
      isChecked: true
    }
  },
  methods: {
    showMultilayer() {
      this.$emit('showMultilayer', !this.isChecked)
    }
  }
}
</script>

<style scoped lang="sass">
.multilayer-toggler
  display: flex
  align-items: center
  margin: 0 rem(8) 0 0
  &__input
    position: absolute
    display: none
    pointer-events: none
    opacity: 0
  &__label
    height: rem(24)
    width: rem(24)
    background: $cool_B
    border-radius: rem(4)
    transition: .5s
    color: $red
    cursor: pointer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    svg
      path
        transition: .5s
</style>
