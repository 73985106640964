<template>
  <div class="search-results" v-if="isResultsFounded">
    <search-results-item
      v-for="item in searchResults"
      :layer-id="layerId"
      :item="item"
      :key="item.id"
      @click="selectMaterial(item.id)"
    />
  </div>
  <i18n path="message.system.search.notFound" class="search-results__error" tag="div" v-else>
    <template #query>
      <span>"{{ searchQuery }}"</span>
    </template>
  </i18n>
</template>

<script>
import SearchResultsItem from '@/components/smart/MaterialsSearch/SearchResultsItem'
export default {
  components: { SearchResultsItem },
  props: {
    layerId: {
      type: Number,
      default: null
    },
    searchQuery: {
      type: String,
      required: true
    },
    searchResults: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectMaterial(materialId) {
      this.$emit('selectMaterial', materialId)
    }
  },
  name: 'SearchResults',
  computed: {
    isResultsFounded() {
      return !!this.searchResults.length
    }
  }
}
</script>

<style scoped lang="sass">
.search-results
  +media((margin: (0: rem(16) rem(-20) 0, 576: rem(16) rem(-40) 0)))
  +media((padding: (0: 0 rem(20), 576: 0 rem(60))))
  max-height: rem(256)
  box-sizing: border-box
  overflow-y: auto
  overflow-x: hidden
  scrollbar-width: thin
  scrollbar-color: rgba($red, .25) transparent
  &::-webkit-scrollbar
    width: rem(4)
  &::-webkit-scrollbar-thumb
    background: $cool_B
  &::-webkit-scrollbar-thumb
    background-color: rgba($red, .25)
    border-radius: 0
  &::-webkit-scrollbar-track
    background: $cool_B
  &__error
    margin: rem(16) 0
    padding: 0 rem(20)
    @extend .fs-12
    color: $dark-grey
    span
      word-break: break-all
  // %styled-native-scrollbar
    scrollbar-width: thin
    scrollbar-color: rgba($RED, .75) transparent
    &::-webkit-scrollbar
      width: rem(4)
    &::-webkit-scrollbar-thumb
      background: $gr10
    &::-webkit-scrollbar-thumb
      background-color: rgba($RED, .75)
      border-radius: 0
    &::-webkit-scrollbar-track
      background: $gr10
</style>
