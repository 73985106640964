<template>
  <draggable
    v-model="parentLayersList"
    group="layers"
    @start="drag = true"
    @end="drag = false"
    handle=".handle"
    class="single-system__layers"
  >
    <div
      v-for="(layer, i) in parentLayersList"
      :key="`layer-${i}`"
      :ref="`layer${i}`"
      @focusin="expandLayers(i)"
      :class="{ 'single-layer--highlight': highlightIndex === i }"
      class="single-layer"
    >
      <div class="single-layer__header single-layer__header--extend">
        <div class="btn-handle handle"></div>
        <div class="single-layer__header-name">
          <div class="text" :class="{ 'text--disabled': layer.wedge && !layer.isEnabled }">
            {{ layer.name }}
          </div>
          <Layer-toggler
            v-if="!layer.immutable && !layer.isBaseLayer"
            :layerIndex="i"
            :isEnabled="layer.isEnabled"
            :id="`wedge-toggler-${layer.id}-${i}`"
          />
        </div>
        <CircleMenu
          @clickedFromCircleMenu="parentLayerMenuAction($event, i)"
          :setUp="getButtonsSetup(parentLayers.length, i, layer, null)"
        >
          <template #icon>
            <img src="@/assets/img/icons/layer--parent-menu.svg" alt="" />
          </template>
        </CircleMenu>
      </div>
      <draggable
        :value="layer.items"
        :group="`items-${i}`"
        @start="drag = true"
        @end="drag = false"
        @input="emmiter($event, i)"
        handle=".handle"
        class="single-layer__body"
      >
        <div
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
          class="single-layer__body-item single-layer__body-item--extend"
        >
          <div
            class="btn-handle handle"
            :class="{
              'btn-handle--extend': currentFastenerInfo === `layer-${getCurrentNumber(i, n)}`
            }"
          ></div>
          <div class="number" :class="{ 'number--expanded': isLayerHasFastener(item) }">
            <div class="number__text">
              {{ getCurrentNumber(i, n) }}
            </div>
          </div>
          <div
            class="elements-base"
            v-if="isBaseLayer(layer)"
            :class="{ 'elements-base--disabled': !item.hasAlternate }"
          >
            <BaseTypeSelect
              :baseTypeId="item.id"
              :layerIndex="i"
              :itemIndex="n"
              @showProducts="request($event, 'base', layer.name, i, n, item.id, true)"
            />
            <div class="elements-base__body">
              <div
                class="layer-select"
                @click="request(item.name, 'usual', layer.name, i, n, item.id)"
              >
                {{ item.material.name }}
              </div>
              <Input-or-select
                :item="item"
                v-if="item.material.valueType"
                :parent-type="'system'"
                :layerIndex="i"
                :isInCustom="true"
                :itemIndex="n"
              />
            </div>
          </div>
          <div class="elements" v-else-if="isItemHasMaterial(item) && !isMultilayerItem(layer.name, item) && !item.materials">
            <div
              class="elements__left"
              @click="request(item.name, layer.name, 'usual', i, n, item.id)"
              :class="{ 'elements__left--full': !isShowInputOrSelect(item.material) }"
            >
              <div class="elements__left-info">
                <div class="name">{{ item.name }}</div>
                <div class="material">{{ item.material.name }}</div>
              </div>
              <div class="chevron">
                <img class="" src="@/assets/img/icons/filter--select.svg" alt="↓" />
              </div>
            </div>
            <Input-or-select
              :item="item"
              v-if="isItemHasMaterial(item) && isShowInputOrSelect(item.material)"
              :layerIndex="i"
              :parent-type="'system'"
              :isInCustom="true"
              :itemIndex="n"
            />
          </div>
          <multilayer
              v-else
              :group="item"
              :sectorIndex="sectorIndex"
              :layerIndex="i"
              :itemIndex="n"
              @getAlternativeMaterials="request(item.name, layer.name, 'usual', i, n, item.id, false, true)"
          ></multilayer>
          <CircleMenu
            @clickedFromCircleMenu="childLayerMenuAction($event, i, n, item.id)"
            v-if="!layer.isBaseLayer && isEditable(layer.name, item, i, n, true)"
            :setUp="getButtonsSetup(layer.items.length, n, item, layer.name)"
          >
            <template #icon>
              <img src="@/assets/img/menu.svg" alt="" />
            </template>
          </CircleMenu>
          <div
              class="elements elements--fastener"
              v-if="isLayerHasFastener(item)"
              :class="{ 'elements--fastener_separated': isMultilayerItem(layer.name, item) || item.materials }"
          >
            <fastener-info
              :is-wedge="item.wedge"
              :itemIndex="n"
              :layerIndex="i"
              :fastenerId="item.fasteners.selected"
              :base="findBaseType(getFastenerParams(i, n).fastenerBase)"
              :height="getFastenerParams(i, n).fastenerHeight"
              :rate="item.computedFastenersRate"
              :isShowCheckBox="isKlinInWedgeExist"
              :is-in-custom="true"
              :currentChecked="currentFastenerInfo"
              :id="`layer-${getCurrentNumber(i, n)}`"
              @showFastenerInfo="showFastenerInfo"
              :base-type-id="getCurrentBaseTypeIdByLayerId(layer.id)"
            />
          </div>
        </div>
        <Item-add
          :layerIndex="i"
          :layerId="layer.id"
          placement="system"
          v-if="isShowItemAddBtn(layer)"
          @getNewLayer="getNewLayer($event, layer.name, i, layer.items.length)"
        />
      </draggable>
    </div>
    <Layer-add placement="system"/>
    <Modal :isModalOpen="isAddFastenerOpen" :isShowClose="false" @close="closeFastenerAdd">
      <template #body>
        <Fastener-selection
          :layerIndex="fastenerAddLayerIndex"
          :itemIndex="fastenerAddItemIndex"
          :base-type-id="getCurrentBaseTypeIdByLayerIndex(fastenerAddLayerIndex)"
          placement="system"
          :is-in-custom="true"
          :is-add-new-fastener="true"
          @close="closeFastenerAdd"
        />
      </template>
    </Modal>
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :can-close="isCanClose"
      @close="closeModal"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="target"
          :response="responseItems"
          @deleteAndClose="deleteAndClose"
          @close="selectProductAndClose"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </draggable>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapState, mapMutations, mapGetters } from 'vuex'
import {
  getItemNumber,
  getFastenerInfo,
  isLayerHasFastener,
  isTargetLayerInWedgeExist,
  isShowElement
} from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import CircleMenu from '@/components/elements/Dom/CircleMenu'
import draggable from 'vuedraggable'
import LayerToggler from '@/components/elements/Layers/LayerToggler'
import LayerAdd from '@/components/elements/Layers/LayerAdd'
import ItemAdd from '@/components/elements/Layers/ItemAdd'
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
import BaseTypeSelect from '@/components/elements/Dom/BaseTypeSelect'
import { getMaterialsForLayer } from '@/api'
import FastenerSelection from '@/components/elements/Modals/FastenerSelection'
import FastenerInfo from '@/components/elements/Layers/Fastener-info'
import Multilayer from '@/components/smart/System/Multilayers/Multilayer'
import multilayerMixin from '@/mixins/multilayerMixin'

export default {
  props: {
    highlightIndex: {
      type: Number,
      default: null
    }
  },
  mixins: [multilayerMixin],
  data: () => ({
    isModalOpen: false,
    currentFastenerInfo: '',
    isAddFastenerOpen: false,
    fastenerAddLayerIndex: '',
    fastenerAddItemIndex: '',
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isShowParentLayerAddList: false,
    layersPath: [],
    isLoading: false,
    responseItems: {},
    isCanClose: false,
    isBaseLayerCategoryChanged: false,
    target: 'system'
  }),
  components: {
    Multilayer,
    FastenerInfo,
    FastenerSelection,
    draggable,
    CircleMenu,
    LayerToggler,
    LayerAdd,
    ItemAdd,
    Modal,
    LayerProductsView,
    InputOrSelect,
    BaseTypeSelect
  },
  methods: {
    ...mapMutations({
      DELETE_SECTOR_SYSTEM_CHILD_LAYER: 'DELETE_SECTOR_SYSTEM_CHILD_LAYER',
      ADD_FASTENER_TO_LAYER: 'ADD_FASTENER_TO_LAYER',
      REMOVE_FASTENER_FROM_LAYER: 'REMOVE_FASTENER_FROM_LAYER',
      ADD_CHILD_LAYER_TO_SYSTEM: 'ADD_CHILD_LAYER_TO_SYSTEM',
      UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: 'UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS'
    }),
    getCurrentBaseTypeIdByLayerId(layerId) {
      const currentOrderedLayers = [...this.parentLayersList]
      const currentLayerPosition = currentOrderedLayers.findIndex((layer) =>
        layer.id === layerId && layer?.items.find(item => item?.material?.isMechanicalFasteners)
      )

      const layersWithFasteners = this.parentLayersList
        .filter((layer, index) =>
          index < currentLayerPosition && layer?.items.find(item => item?.material?.isBaseForFasteners)
        )

      const higherBaseForFasteners = layersWithFasteners?.length
        ? layersWithFasteners.reverse()[0]
        : currentOrderedLayers.find(layer => layer.isBaseLayer)

      const [firstLayer] = higherBaseForFasteners.items

      return firstLayer?.baseTypeId || firstLayer?.material?.baseTypeId
    },
    getCurrentBaseTypeIdByLayerIndex(index) {
      return this.getCurrentBaseTypeIdByLayerId(this.parentLayersList[index]?.id)
    },
    expandLayers(layerIndex) {
      if (!this.parentLayers[layerIndex].expanded) {
        this.$emit('highlightLayer', { layerIndex: layerIndex, placement: 'table' })
      }
    },
    closeFastenerAdd() {
      this.isAddFastenerOpen = false
      this.currentFastenerInfo = `layer-${this.getCurrentNumber(
        this.fastenerAddLayerIndex,
        this.fastenerAddItemIndex
      )}`
      this.fastenerAddLayerIndex = ''
      this.fastenerAddItemIndex = ''
    },
    isLayerHasFastener(item) {
      return isLayerHasFastener(item)
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    getFastenerParams(totalIndex, localIndex) {
      let arr = cloneDeep(this.parentLayers)
      return getFastenerInfo(totalIndex, localIndex, arr, this.defaultBaseId)
    },
    showFastenerInfo(id) {
      this.currentFastenerInfo = id
    },
    isShowItemAddBtn(layer) {
      const isBaseLayer = Object.prototype.hasOwnProperty.call(layer, 'isBaseLayer')
      return !(isBaseLayer && layer.items.length === 1)
    },
    isItemHasMaterial(item) {
      return Object.prototype.hasOwnProperty.call(item, 'material')
    },
    getNewLayer(newLayer, layer, layerIndex, itemIndex) {
      this.ADD_CHILD_LAYER_TO_SYSTEM({
        sectorIndex: this.sectorIndex,
        layerIndex: layerIndex,
        layer: newLayer
      })

      this.isLoading = true
      getMaterialsForLayer(this.$i18n.locale, 'system', null, null, 'custom', newLayer.id).then(
        response => {
          this.isLoading = false
          this.isModalOpen = !this.isModalOpen
          this.layerIndexToSelect = layerIndex
          this.itemIndexToSelect = itemIndex
          this.isCanClose = false
          this.layersPath = [layer, newLayer.name]
          this.responseItems = response.data
        }
      )
    },
    request(
      itemName,
      placement,
      layerName,
      layerIndex,
      itemIndex,
      layerId,
      isBaseLayerCategoryChanged = false,
      mechanicalMount = false
    ) {
      this.isBaseLayerCategoryChanged = isBaseLayerCategoryChanged
      this.isLoading = true
      getMaterialsForLayer(this.$i18n.locale, 'system', null, null, 'custom', layerId, mechanicalMount).then(
        response => {
          this.isLoading = false
          this.target = mechanicalMount ? 'multilayer' : 'system'
          this.isModalOpen = !this.isModalOpen
          this.layerIndexToSelect = layerIndex
          this.itemIndexToSelect = itemIndex
          this.responseItems = response.data
          this.isCanClose = placement !== 'base'
          this.layersPath = [layerName, itemName]
        }
      )
    },
    isShowInputOrSelect(material) {
      return isShowElement(material)
    },
    isBaseLayer(layer) {
      return Object.prototype.hasOwnProperty.call(layer, 'isBaseLayer')
    },
    closeModal(val) {
      const isDeleteNewLayer = val?.isDeleteNewLayer || false
      if (!this.isBaseLayerCategoryChanged) {
        if (isDeleteNewLayer) {
          this.DELETE_SECTOR_SYSTEM_CHILD_LAYER({
            sectorIndex: this.sectorIndex,
            layerIndex: this.layerIndexToSelect,
            itemIndex: this.itemIndexToSelect
          })
        }
        this.isModalOpen = false
        this.isBaseLayerCategoryChanged = false
      } else {
        this.$notify({
          group: 'layerError',
          type: 'error',
          title: this.$t('message.customLayer.layerError')
        })
      }
    },
    selectProductAndClose() {
      this.isModalOpen = false
      this.isBaseLayerCategoryChanged = false
    },
    deleteAndClose(args) {
      this.DELETE_SECTOR_SYSTEM_CHILD_LAYER({
        sectorIndex: this.sectorIndex,
        layerIndex: args.layerIndex,
        itemIndex: args.itemIndex
      })
      this.currentFastenerInfo = ''
      this.isModalOpen = false
      this.isBaseLayerCategoryChanged = false
    },
    parentLayerMenuAction(action, currentIndex) {
      if (action === 'up') {
        const arr = cloneDeep(this.parentLayers)
        ;[arr[currentIndex], arr[currentIndex - 1]] = [arr[currentIndex - 1], arr[currentIndex]]
        this.$store.commit('UPDATE_SECTOR_SYSTEM_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          value: arr
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'down') {
        const arr = cloneDeep(this.parentLayers)
        ;[arr[currentIndex], arr[currentIndex + 1]] = [arr[currentIndex + 1], arr[currentIndex]]
        this.$store.commit('UPDATE_SECTOR_SYSTEM_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          value: arr
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'delete') {
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList.splice(currentIndex, 1)
        this.parentLayersList = Object.assign([], newParentLayersList)
        this.currentFastenerInfo = ''
      }
    },
    childLayerMenuAction(action, parentIndex, itemIndex, id) {
      if (action === 'up') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex - 1]] = [arr[itemIndex - 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
        this.currentFastenerInfo = ''
      }
      if (action === 'down') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex + 1]] = [arr[itemIndex + 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
        this.currentFastenerInfo = ''
      }
      if (action === 'delete') {
        this.DELETE_SECTOR_SYSTEM_CHILD_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex: itemIndex
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'addFastener') {
        this.fastenerAddLayerIndex = parentIndex
        this.fastenerAddItemIndex = itemIndex
        this.isAddFastenerOpen = true
        this.currentFastenerInfo = ''
      }
      if (action === 'removeFastener') {
        this.REMOVE_FASTENER_FROM_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex: itemIndex
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'multilayer') {
        this.editMultilayerItems(parentIndex, itemIndex, id)
      }
    },
    emmiter(val, index) {
      const [...newParentLayersList] = this.parentLayersList
      newParentLayersList[index].items = val
      this.parentLayersList = Object.assign([], newParentLayersList)
      this.currentFastenerInfo = ''
    },
    findBaseType(id) {
      const target = this.baseTypes.find(p => p.id === id)
      return target ? target.name : this.baseTypes.find(p => p.id === this.defaultBaseId).name
    }
  },
  computed: {
    ...mapState({
      layersProducts: state => state.layersProducts,
      baseTypes: state => state.baseTypes,
      defaultBaseId: state => state.defaultBaseId,
      layersList: state => state.layersList
    }),
    ...mapGetters({
      getItemsByParent: 'getItemsByParent'
    }),
    parentLayersList: {
      get() {
        return this.parentLayers
      },
      set(value) {
        this.$store.commit('UPDATE_SECTOR_SYSTEM_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          value: value
        })
        this.currentFastenerInfo = ''
      }
    },
    sectorIndex() {
      return this.$route.params.id ? Number(this.$route.params.id) : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    parentLayers() {
      return this.sectorFromState.layers
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    }
  }
}
</script>

<style lang="sass" scoped>
.single-system__layers
  .single-layer
    &__header
      &--extend
        +media((grid-template-columns: (320: 1fr rem(40), 768: rem(20) 1fr rem(40))))
    &__body
      &-item
        &--extend
          +media((grid-template-columns: (320: 2fr 1fr 22fr rem(32), 768: rem(20) 2fr 1fr 22fr 2fr)))
          .btn-handle--extend
            grid-row: 1 / 3
          .elements, .elements-base
            +media((grid-column-start: (320: 3, 768: 4)))
          .elements
            &--fastener
              +media((grid-column: (320: '3 / 4', 768: '4 / 5')))
              &.elements--fastener_separated
                padding: rem(15) 0 0
                box-shadow: inset 0 0.0625rem 0 #ececec
      &-item:has(.elements_multilayer)
        .number__text
          justify-content: flex-start
          padding: rem(28) 0 0 0
</style>
