import { render, staticRenderFns } from "./DefaultFastener.vue?vue&type=template&id=63cb3525&"
import script from "./DefaultFastener.vue?vue&type=script&lang=js&"
export * from "./DefaultFastener.vue?vue&type=script&lang=js&"
import style0 from "./DefaultFastener.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports