<template>
  <div class="image-wrapper">
    <button class="fullscreen__btn" @click="fullScreen" v-if="isShowFullScreen">
      <img src="@/assets/img/icons/fullscreen.svg" alt="" />
    </button>
    <svg
      width="100%"
      ref="schemeSvg"
      id="diagram"
      :height="optimizedTotalHeight"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="`0 0 ${currentViewBoxWidth} ${optimizedTotalHeight}`"
    >
      <g
        v-for="(layer, i) in filteredLayers"
        :key="i"
        class="layers__group"
        @click="highlightLayer(layer.realIndex)"
      >
        <!--@focusout="highlightLayer(null)"-->
        <g
          v-for="(item, n) in layer.items"
          :key="`schemeLayer-${i}-item-${n}`"
          class="layers__group-item"
        >
          <Default-layer
            v-if="isDefaultLayer(item.material.category)"
            :i="i"
            :n="n"
            :item="item"
            :color="getColor(item.material.category)"
            :figures="getPath(i, n, item.material.value)"
            :current-number="getCurrentNumber(layer.realIndex, n)"
            :real-index="layer.realIndex"
            :is-layer-has-fastener="isLayerHasFastener(item)"
            :value="item.material.value"
            :coefficient="coefficient"
            :is-in-mobile-modal="isInMobileModal"
            :is-in-full-screen="isInFullScreen"
          >
            <template #fastener>
              <Default-fastener
                :i="i"
                :n="n"
                :is-in-full-screen="isInFullScreen"
                :value="item.material.value"
                :real-index="layer.realIndex"
                :fastener-name="getFastenerName(item)"
                :fastener-height="getFastenerParams(i, n).fastenerHeight"
                :current-number="getCurrentNumber(layer.realIndex, n)"
                :coefficient="coefficient"
                :filtered-layers="filteredLayers"
                :parent-layers="parentLayers"
                :fasteners-on-scheme="fastenersOnScheme"
                :default-base-id="defaultBaseId"
                :is-in-mobile-modal="isInMobileModal"
              />
            </template>
          </Default-layer>
          <Image-layer
            v-else
            :figures="getPath(i, n, item.material.value, true)"
            :item="item"
            :current-number="getCurrentNumber(layer.realIndex, n)"
            :image="getColor(item.material.category)"
            :is-layer-has-fastener="isLayerHasFastener(item)"
            :coefficient="coefficient"
            :is-in-mobile-modal="isInMobileModal"
            :is-in-full-screen="isInFullScreen"
          >
            <template #fastener>
              <Default-fastener
                :i="i"
                :n="n"
                :is-in-full-screen="isInFullScreen"
                :value="item.material.value"
                :real-index="layer.realIndex"
                :fastener-name="getFastenerName(item)"
                :fastener-height="getFastenerParams(i, n).fastenerHeight"
                :current-number="getCurrentNumber(layer.realIndex, n)"
                :coefficient="coefficient"
                :filtered-layers="filteredLayers"
                :parent-layers="parentLayers"
                :fasteners-on-scheme="fastenersOnScheme"
                :default-base-id="defaultBaseId"
                :is-in-mobile-modal="isInMobileModal"
              />
            </template>
          </Image-layer>
        </g>
      </g>
      <defs>
        <linearGradient :id="gradientId" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop stop-color="white" stop-opacity="0.7"></stop>
          <stop offset="1" stop-color="white" stop-opacity="0.4"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapMutations, mapState } from 'vuex'
import {
  getLayersTotalHeight,
  getFilteredLayers,
  getPath,
  getTotalFastenersCount
} from '@/utils/drawing'
import { isLayerHasFastener, getFastenerInfo, getItemNumber } from '@/utils/customFunctions'

import { vueWindowSizeMixin } from 'vue-window-size'
import DefaultLayer from '@/components/smart/System/Scheme/DefaultLayer'
import ImageLayer from '@/components/smart/System/Scheme/ImageLayer'
import DefaultFastener from '@/components/smart/System/Scheme/DefaultFastener'
export default {
  mixins: [vueWindowSizeMixin],
  props: {
    isShowFullScreen: {
      type: Boolean,
      default: false
    },
    isInMobileModal: {
      type: Boolean,
      default: false
    },
    isInFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentIndex: null
  }),
  components: {
    DefaultLayer,
    ImageLayer,
    DefaultFastener
  },
  methods: {
    ...mapMutations({
      EXPAND_LAYERS: 'EXPAND_LAYERS',
      COLLAPSE_LAYERS: 'COLLAPSE_LAYERS'
    }),
    getPath(layerIndex, itemIndex, height, isImageLayer = false) {
      return getPath(
        this.filteredLayers,
        layerIndex,
        itemIndex,
        height,
        this.coefficient,
        this.isInFullScreen,
        isImageLayer
      )
    },
    // getFastenerPath(layerIndex, itemIndex, height, layerRealIndex) {
    //   const fastenerHeight = this.getFastenerParams(layerIndex, itemIndex).fastenerHeight
    //   const collectedLayers = this.getFastenerParams(layerIndex, itemIndex).collectedLayers
    //   const layerNumber = this.getCurrentNumber(layerRealIndex, itemIndex)
    //   return getFastenerPath(this.filteredLayers, layerIndex, itemIndex, height, this.coefficient, fastenerHeight, collectedLayers, this.fastenersOnScheme, layerNumber)
    // },
    highlightLayer(layerIndex) {
      // this.currentIndex = this.currentIndex === layerIndex ? null : layerIndex
      this.$emit('highlightLayer', { layerIndex: layerIndex, placement: 'scheme' })
    },
    collapseLayers() {
      // this.$emit('highlightLayer', layerIndex)
      this.COLLAPSE_LAYERS(this.sectorIndex)
    },
    getFastenerParams(totalIndex, localIndex) {
      const arr = cloneDeep(this.filteredLayers)
      return getFastenerInfo(totalIndex, localIndex, arr, this.defaultBaseId)
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    isLayerHasFastener(item) {
      return isLayerHasFastener(item)
    },
    getFastenerName(item) {
      if (this.isLayerHasFastener(item)) {
        return this.fasteners.find(p => p.id === item.fasteners.selected).name
      }
    },
    findCurrentItem(layerId, itemId) {
      return this.systemLayersList.find(p => p.id === layerId).items.find(p => p.id === itemId)
    },
    getColor(id) {
      const currentCategory = this.materialCategories.find(p => p.id === id)
      if (typeof currentCategory === 'undefined') {
        return [{ color: `#${Math.floor(Math.random() * 16777215).toString(16)}` }]
      } else {
        if (Object.prototype.hasOwnProperty.call(currentCategory, 'schemeImage')) {
          return currentCategory.schemeImage
        } else if (
          Object.prototype.hasOwnProperty.call(currentCategory, 'schemeColor') &&
          currentCategory.schemeColor.length
        ) {
          return currentCategory.schemeColor
        } else {
          return [{ color: 'red' }]
        }
      }
    },
    isDefaultLayer(id) {
      const currentCategory = this.materialCategories.find(p => p.id === id)
      if (typeof currentCategory === 'undefined') {
        return true
      } else {
        if (Object.prototype.hasOwnProperty.call(currentCategory, 'schemeImage')) {
          return false
        } else if (Object.prototype.hasOwnProperty.call(currentCategory, 'schemeColor')) {
          return true
        } else {
          return true
        }
      }
    },
    fullScreen() {
      this.$emit('fullScreen')
    }
  },

  computed: {
    ...mapState({
      defaultBaseId: state => state.defaultBaseId,
      fasteners: state => state.fasteners,
      systemLayersList: state => state.layersList,
      materialCategories: state => state.materialCategories
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    parentLayers() {
      return this.sectorFromState.layers
    },
    totalHeight() {
      return getLayersTotalHeight(this.filteredLayers, this.isInFullScreen)
    },
    filteredLayers() {
      return getFilteredLayers(this.parentLayers, this.materialCategories)
    },
    coefficient() {
      if (this.isInFullScreen) {
        return 1
      } else {
        return this.windowHeight > this.totalHeight ? 1 : this.windowHeight / this.totalHeight
      }
    },
    optimizedTotalHeight() {
      return this.totalHeight * this.coefficient
    },
    fastenersOnScheme() {
      return getTotalFastenersCount(this.parentLayers)
    },
    gradientId() {
      return this.isInMobileModal ? 'gradient--mobile' : 'gradient--desktop'
    },
    currentViewBoxWidth() {
      return this.isInFullScreen ? 960 : 336
    }
  }
}
</script>

<style lang="sass">
.image-wrapper
  position: sticky
  top: 0
  .fullscreen__btn
    position: absolute
    +media((right: (960: unset, 1040: rem(-40))))
    +media((left: (960: rem(-40), 1040: unset)))
    top: 0
    width: rem(40)
    height: rem(40)
    @extend .clear-btn
    background: $red
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    border-radius: 50%
  svg
    transition: .5s
  .layers
    &__group
      &-item
        cursor: pointer
        &:focus
          outline: none
        .texture
          pointer-events: none
        .polygon, .circuit, .fastener-body, .fastener-hat, .texture
          pointer-events: none
          transition: .5s
          outline: none
        .fastener-body
          pointer-events: auto
        .hexagon
          outline: none
          transition: .5s
          &:hover
            filter: contrast(.5)
          &__top
            outline: none
            transition: .5s
            pointer-events: none
          &__image
            outline: none
            transition: .5s
.sidebar--mobile
  .image-wrapper
    width: 100vw
    max-width: rem(400)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: rem(40) 0
    svg
      +media((width: (320: rem(300), 400: rem(336), 960: 100%)))
.scheme-text
  color: $black
  font-size: rem(12)
  font-weight: 500
  font-family: $main-font
  line-height: 150%
</style>
