<template>
  <input
    class="search-input"
    type="text"
    :value="searchQuery"
    @input="handleInput"
    :placeholder="$t('message.system.search.inputPlaceholder')"
    @focus="updateFocusStatus(true)"
    @blur="updateFocusStatus(false)"
  />
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      required: true
    }
  },
  name: 'SearchInput',
  methods: {
    updateFocusStatus(val) {
      this.$emit('updateFocusStatus', val)
    },
    handleInput(e) {
      this.$emit('search', e.target.value)
    }
  }
  // computed: {
  //   computedSearchQuery: {
  //     get() {
  //       return this.searchQuery
  //     },
  //     set(val) {
  //       this.$emit('search', val)
  //     }
  //   }
  // }
}
</script>

<style scoped lang="sass">
.search-input
  font-style: normal
  font-size: rem(16)
  font-family: $main-font
  color: $black
  font-weight: bold
  border-radius: rem(4)
  $w-l: calc(100% - 40px)
  $w-s: 100%
  +media((width: (0: $w-s, 576: $w-l)))
  margin: 0 auto
  border: rem(1) solid $cool_A
  outline: none
  min-height: rem(48)
  vertical-align: middle
  display: flex
  align-items: center
  text-overflow: ellipsis
  padding: rem(12) rem(45) rem(12) rem(20)
  box-sizing: border-box
  caret-color: $red
  transition: .5s
  background-image: url("~@/assets/img/icons/search.svg")
  background-repeat: no-repeat
  background-position: calc(100% - 15px) center
  &:focus
    box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3), 0 rem(5) rem(30) rgba(51, 51, 51, 0.2)
    border-color: transparent
  &::-webkit-input-placeholder
    color: $light-black
    font-weight: normal
  &:-moz-placeholder
    color: $light-black
    font-weight: normal
  &::-moz-placeholder
    color: $light-black
    font-weight: normal
  &:-ms-input-placeholder
    color: $light-black
    font-weight: normal
</style>
