<template>
  <g class="layers__group-item">
    <text x="400" :y="figures.textY" v-if="isInFullScreen" class="scheme-text">
      № {{ currentNumber }} |
      <tspan font-weight="bold">
        {{ item.material.name }}
      </tspan>
      | {{ item.material.value }} {{ $t('message.units.mm') }}
    </text>
    <path
      :d="figures.hexagon"
      class="hexagon"
      :content="`<span class='hexagon__tooltip'>№ ${currentNumber} | <strong>${
        item.material.name
      }</strong><span class='hexagon__tooltip-info'> | ${item.material.value}${$t(
        'message.units.mm'
      )}</span></span>`"
      v-tippy="{
        followCursor: false,
        animation: 'shift-toward',
        theme: 'light',
        delay: 100,
        arrow: !isInMobileModal,
        placement: isInMobileModal ? 'top bottom' : 'left'
      }"
      :fill="color[0].color"
    >
      <!--:fill="`#${Math.floor(Math.random()*16777215).toString(16)}`"-->
    </path>
    <path :d="figures.hexagon" class="texture" :fill="`url(#${patternId})`"> </path>
    <path :d="figures.hexagonTop" v-if="isDoubleLayer" class="hexagon__top" :fill="color[1].color">
      <!--v-if="isDoubleLayer"-->
    </path>
    <path
      :d="figures.hexagonTop"
      v-if="isDoubleLayer"
      class="texture"
      :fill="`url(#${patternId}--top)`"
    >
      <!--v-if="isDoubleLayer"-->
    </path>
    <path
      :d="figures.circuit"
      stroke-width="2"
      stroke="white"
      fill="none"
      class="circuit"
      stroke-opacity="0.4"
    >
    </path>
    <path opacity="0.15" :d="figures.polygonLeft" fill="black" class="polygon"></path>
    <path opacity="0.15" :d="figures.polygonRight" fill="white" class="polygon"></path>
    <path :d="figures.polygonTop" :fill="`url(${gradientId})`" class="polygon"></path>
    <slot name="fastener" v-if="isLayerHasFastener"></slot>
    <defs>
      <pattern :id="patternId" x="0" y="0" width="128" height="128" patternUnits="userSpaceOnUse">
        <image :href="color[0].pattern" x="0" y="0" height="128" width="128" />
      </pattern>
    </defs>
    <defs v-if="isDoubleLayer">
      <!--v-if="isDoubleLayer"-->
      <pattern
        :id="`${patternId}--top`"
        x="0"
        y="0"
        width="128"
        height="128"
        patternUnits="userSpaceOnUse"
      >
        <image :href="color[1].pattern" x="0" y="0" height="128" width="128" />
      </pattern>
    </defs>
  </g>
</template>

<script>
export default {
  props: {
    i: Number,
    n: Number,
    item: Object,
    figures: Object,
    currentNumber: Number,
    realIndex: Number,
    isLayerHasFastener: Boolean,
    value: Number,
    coefficient: Number,
    isInMobileModal: Boolean,
    color: Array,
    isInFullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    patternId() {
      return this.isInMobileModal
        ? `mobile--pattern-layer${this.realIndex}-item${this.n}`
        : `desktop--pattern-layer${this.realIndex}-item${this.n}`
    },
    isDoubleLayer() {
      return this.color.length > 1
    },
    gradientId() {
      return this.isInMobileModal ? '#gradient--mobile' : '#gradient--desktop'
    }
  }
}
</script>

<style scoped lang="sass"></style>
