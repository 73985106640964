<template>
  <div class="single-system__layers">
    <div
      v-for="(layer, i) in parentLayers"
      :key="`layer-${i}`"
      tabindex="1"
      class="single-layer"
      @focusin="expandLayers(i)"
      :class="{ 'single-layer--highlight': highlightIndex === i }"
      :ref="`layer${i}`"
    >
      <div class="single-layer__header">
        <div class="single-layer__header-name">
          <div class="text" :class="{ 'text--disabled': layer.wedge && !layer.isEnabled }">
            {{ layer.name }}
          </div>
          <Layer-toggler
            v-if="!layer.immutable && !layer.isBaseLayer"
            :layerIndex="i"
            :isEnabled="layer.isEnabled"
            :id="`layer-toggler-${layer.id}-${i}`"
          />
        </div>
      </div>
      <draggable
        :value="layer.items"
        :group="`items-${i}`"
        class="single-layer__body"
        @start="drag = true"
        @end="drag = false"
        @input="emmiter($event, i)"
        handle=".handle"
      >
        <div
          class="single-layer__body-item single-layer__body-item--extend"
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
        >
          <div
            class="btn-handle handle btn-handle--extend"
            :style="{ visibility: item.isAdditional ? 'visible' : 'hidden' }"
          ></div>
          <div class="number" :class="{ 'number--expanded': isLayerHasFastener(item) }">
            <div class="number__text">
              {{ getCurrentNumber(i, n) }}
            </div>
          </div>
          <div
            class="elements-base"
            v-if="isBaseLayer(layer)"
            :class="{ 'elements-base--disabled': !item.hasAlternate }"
          >
            <div class="elements-base__head">
              {{ item.name }}
            </div>
            <div class="elements-base__body">
              <div
                class="layer-select"
                :class="{ 'layer-select--disabled': !item.hasAlternate }"
                @click="item.hasAlternate ? request(item.name, 'usual', layer.name, i, n) : null"
              >
                {{ item.material.name }}
              </div>
              <Input-or-select
                :item="item"
                v-if="isShowInputOrSelect(item.material)"
                :layerIndex="i"
                :parent-type="'system'"
                :isInCustom="false"
                :itemIndex="n"
              />
            </div>
          </div>
          <div class="elements" v-else-if="!isMultilayerItem(layer.name, item) && !item.materials">
            <div
              class="elements__left"
              @click="item.hasAlternate ? request(item.name, 'usual', layer.name, i, n) : null"
              :class="{
                'elements__left--full': !isShowInputOrSelect(item.material),
                'elements__left--disabled': !item.hasAlternate
              }"
            >
              <div class="elements__left-info">
                <div class="name">{{ item.name }}</div>
                <div class="material">{{ item.material.name }}</div>
              </div>
              <div class="chevron">
                <img
                  class=""
                  src="@/assets/img/icons/filter--select.svg"
                  alt="↓"
                  v-if="item.hasAlternate"
                />
                <img
                  class=""
                  src="@/assets/img/icons/filter--select--disabled.svg"
                  alt="↓"
                  v-else
                />
              </div>
            </div>
            <Input-or-select
              :item="item"
              v-if="isShowInputOrSelect(item.material)"
              :layerIndex="i"
              :parent-type="'system'"
              :isInCustom="false"
              :itemIndex="n"
            />
          </div>
          <multilayer
              v-else
              :group="item"
              :sectorIndex="sectorIndex"
              :layerIndex="i"
              :itemIndex="n"
              @getAlternativeMaterials="request(item.name, layer.name, 'usual', i, n, true)"
          ></multilayer>
          <CircleMenu
            @clickedFromCircleMenu="childLayerMenuAction($event, i, n, item.id)"
            v-if="isEditable(layer.name, item, i, n, false) || isShowItemAddBtn(layer)"
            :setUp="getButtonsSetup(layer.items.length, n, item, layer.name)"
          >
            <template #icon>
              <img src="@/assets/img/menu.svg" alt="" />
            </template>
          </CircleMenu>
          <div
              class="elements elements--fastener"
              v-if="isLayerHasFastener(item)"
              :class="{ 'elements--fastener_separated': isMultilayerItem(layer.name, item) || item.materials }"
          >
            <fastener-info
              :is-wedge="item.wedge"
              :itemIndex="n"
              :layerIndex="i"
              :fastenerId="item.fasteners.selected"
              :base="findBaseType(getFastenerParams(i, n).fastenerBase)"
              :height="getFastenerParams(i, n).fastenerHeight"
              :rate="item.computedFastenersRate"
              :isShowCheckBox="isKlinInWedgeExist"
              :currentChecked="currentFastenerInfo"
              :available-fasteners="item.fasteners.options"
              :id="`layer-${getCurrentNumber(i, n)}`"
              @showFastenerInfo="showFastenerInfo"
              :is-rate-mutable="item.fasteners.mutable"
              :is-in-custom="false"
            />
          </div>
        </div>
        <Item-add
          :layerIndex="i"
          :sectorIndex="sectorIndex"
          :layerId="layer.id"
          :additional-items="layer.additional_items"
          :added-items="layer.items.filter(el => el.isAdditional)"
          placement="system"
          :custom="false"
          @addMountLayer="addMountLayer"
          v-if="isShowItemAddBtn(layer)"
          @getNewLayer="getNewLayer($event, layer.name, i, layer.items.length)"
        />
      </draggable>
    </div>
    <Modal :isModalOpen="isAddFastenerOpen" :isShowClose="false" @close="closeFastenerAdd">
      <template #body>
        <Fastener-selection
          :layerIndex="fastenerAddLayerIndex"
          :itemIndex="fastenerAddItemIndex"
          :base-type-id="currentBaseTypeId"
          placement="system"
          :is-in-custom="true"
          :is-add-new-fastener="true"
          @close="closeFastenerAdd"
        />
      </template>
    </Modal>
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :isShowClose="isShowCloseInModal"
      :can-close="isCanClose"
      @close="close"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="target"
          :response="responseItems"
          @close="close"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  getItemNumber,
  getFastenerInfo,
  isLayerHasFastener,
  isTargetLayerInWedgeExist,
  isLayerHasToggler,
  isShowElement
} from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import CircleMenu from '@/components/elements/Dom/CircleMenu'
import draggable from 'vuedraggable'
import LayerToggler from '@/components/elements/Layers/LayerToggler'
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
import { getMaterialsForLayer } from '@/api'
import FastenerInfo from '@/components/elements/Layers/Fastener-info'
import ItemAdd from '@/components/elements/Layers/ItemAdd'
import FastenerSelection from '@/components/elements/Modals/FastenerSelection'
import Multilayer from '@/components/smart/System/Multilayers/Multilayer'
import multilayerMixin from '@/mixins/multilayerMixin'

export default {
  props: {
    highlightIndex: {
      type: Number,
      default: null
    }
  },
  mixins: [multilayerMixin],
  data: () => ({
    currentFastenerInfo: '',
    isModalOpen: false,
    layersPath: [],
    isAddFastenerOpen: false,
    fastenerAddLayerIndex: '',
    fastenerAddItemIndex: '',
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isShowCloseInModal: true,
    isLoading: false,
    responseItems: {},
    isCanClose: false,
    target: 'system'
  }),
  components: {
    FastenerInfo,
    draggable,
    FastenerSelection,
    CircleMenu,
    ItemAdd,
    Modal,
    LayerProductsView,
    LayerToggler,
    InputOrSelect,
    Multilayer
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: 'UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS',
      ADD_CHILD_LAYER_TO_SYSTEM: 'ADD_CHILD_LAYER_TO_SYSTEM',
      UPDATE_SECTOR_SYSTEM_LAYERS_ORDER: 'UPDATE_SECTOR_SYSTEM_LAYERS_ORDER',
      DELETE_SECTOR_SYSTEM_CHILD_LAYER: 'DELETE_SECTOR_SYSTEM_CHILD_LAYER',
      ADD_FASTENER_TO_LAYER: 'ADD_FASTENER_TO_LAYER',
      REMOVE_FASTENER_FROM_LAYER: 'REMOVE_FASTENER_FROM_LAYER',
      EXPAND_LAYERS: 'EXPAND_LAYERS'
    }),
    isShowItemAddBtn(layer) {
      return Boolean(layer.additional_items)
    },
    getNewLayer(newLayer, layer, layerIndex, itemIndex) {
      this.ADD_CHILD_LAYER_TO_SYSTEM({
        sectorIndex: this.sectorIndex,
        layerIndex: layerIndex,
        layer: newLayer
      })
      this.isLoading = true
      getMaterialsForLayer(this.$i18n.locale, 'system', null, null, 'custom', newLayer.id).then(
        response => {
          this.isLoading = false
          this.isModalOpen = !this.isModalOpen
          this.layerIndexToSelect = layerIndex
          this.itemIndexToSelect = itemIndex
          this.isCanClose = false
          this.layersPath = [layer, newLayer.name]
          this.responseItems = response.data
        }
      )
    },
    expandLayers(layerIndex) {
      if (!this.parentLayers[layerIndex].expanded) {
        this.$emit('highlightLayer', { layerIndex: layerIndex, placement: 'table' })
      }
    },
    isLayerHasToggler(layer) {
      return isLayerHasToggler(layer)
    },
    closeFastenerAdd() {
      this.isAddFastenerOpen = false
      this.currentFastenerInfo = `layer-${this.getCurrentNumber(
        this.fastenerAddLayerIndex,
        this.fastenerAddItemIndex
      )}`
      this.fastenerAddLayerIndex = ''
      this.fastenerAddItemIndex = ''
    },
    isLayerHasFastener(item) {
      return isLayerHasFastener(item)
    },
    close() {
      this.isModalOpen = false
    },
    request(itemName, placement, layerName, layerIndex, itemIndex, mechanicalMount = false) {
      this.isLoading = true
      getMaterialsForLayer(
        this.$i18n.locale,
        'system',
        layerIndex,
        itemIndex,
        'stock',
        this.sectorFromState.system,
        mechanicalMount
      ).then(response => {
        this.isLoading = false
        this.target = mechanicalMount ? 'multilayer' : 'system'
        this.isModalOpen = !this.isModalOpen
        this.layerIndexToSelect = layerIndex
        this.itemIndexToSelect = itemIndex
        this.responseItems = response.data
        this.isShowCloseInModal = placement !== 'base'
        this.isCanClose = placement !== 'base'
        this.layersPath = [layerName, itemName]
      })
    },
    isShowInputOrSelect(material) {
      return isShowElement(material)
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    getFastenerParams(totalIndex, localIndex) {
      let arr = cloneDeep(this.parentLayers)
      return getFastenerInfo(totalIndex, localIndex, arr, this.defaultBaseId)
    },
    isBaseLayer(layer) {
      return Object.prototype.hasOwnProperty.call(layer, 'isBaseLayer')
    },
    addMountLayer(value) {
      this.ADD_MOUNT_LAYER_TO_JUNCTION({
        sectorIndex: this.sectorIndex,
        junctionIndex: this.junctionIndex,
        layerIndex: value
      })
    },
    childLayerMenuAction(action, parentIndex, itemIndex, id) {
      if (action === 'up') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex - 1]] = [arr[itemIndex - 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
        this.currentFastenerInfo = ''
      }
      if (action === 'down') {
        const arr = cloneDeep(this.parentLayers[parentIndex].items)
        ;[arr[itemIndex], arr[itemIndex + 1]] = [arr[itemIndex + 1], arr[itemIndex]]
        const [...newParentLayersList] = this.parentLayersList
        newParentLayersList[parentIndex].items = arr
        this.parentLayersList = Object.assign([], newParentLayersList)
        this.currentFastenerInfo = ''
      }
      if (action === 'delete') {
        this.DELETE_SECTOR_SYSTEM_CHILD_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex: itemIndex
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'addFastener') {
        this.fastenerAddLayerIndex = parentIndex
        this.fastenerAddItemIndex = itemIndex
        this.isAddFastenerOpen = true
        this.currentFastenerInfo = ''
      }
      if (action === 'removeFastener') {
        this.REMOVE_FASTENER_FROM_LAYER({
          sectorIndex: this.sectorIndex,
          layerIndex: parentIndex,
          itemIndex: itemIndex
        })
        this.currentFastenerInfo = ''
      }
      if (action === 'multilayer') {
        this.editMultilayerItems(parentIndex, itemIndex, id)
      }
    },
    emmiter(val, index) {
      const [...newParentLayersList] = this.parentLayersList
      newParentLayersList[index].items = val
      this.parentLayersList = Object.assign([], newParentLayersList)
      this.currentFastenerInfo = ''
    },
    showFastenerInfo(id) {
      this.currentFastenerInfo = id
    },
    findBaseType(id) {
      const target = this.baseTypes.find(p => p.id === id)
      return target ? target.name : this.baseTypes.find(p => p.id === this.defaultBaseId).name
    }
  },
  computed: {
    ...mapState({
      layersProducts: state => state.layersProducts,
      baseTypes: state => state.baseTypes,
      defaultBaseId: state => state.defaultBaseId,
      layersList: state => state.layersList
    }),
    parentLayersList: {
      get() {
        return this.parentLayers
      },
      set(value) {
        this.$store.commit('UPDATE_SECTOR_SYSTEM_LAYERS_ORDER', {
          sectorIndex: this.sectorIndex,
          value: value
        })
        this.currentFastenerInfo = ''
      }
    },
    sectorIndex() {
      return this.$route.params.id ? Number(this.$route.params.id) : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    parentLayers() {
      return this.sectorFromState.layers
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    },
    currentBaseTypeId() {
      const baseLayer = this.parentLayersList.find(layer => {
        return layer.isBaseLayer
      })
      const [firstLayer] = baseLayer.items
      return firstLayer.baseTypeId
    }
  }
}
</script>
<style lang="sass" scoped>
.single-system__layers
  .single-layer
    &__body
        &-item
          &--extend
            +media((grid-template-columns: (320: 2fr 1fr 22fr rem(32), 768: rem(20) 2fr 1fr 22fr 2fr)))
            .btn-handle--extend
              grid-row: 1 / 3
            .elements, .elements-base
              +media((grid-column-start: (320: 3, 768: 4)))
            .elements
              &--fastener
                +media((grid-column: (320: '3 / 4', 768: '4 / 5')))
                &.elements--fastener_separated
                  padding: rem(15) 0 0
                  box-shadow: inset 0 0.0625rem 0 #ececec
        &-item:has(.elements_multilayer)
          .number__text
            justify-content: flex-start
            padding: rem(28) 0 0 0
</style>

<style lang="sass">
.fastener-info__attach-tooltip
  padding: rem(11)
  text-align: left
  font-size: rem(12)
  line-height: 150%
  display: block
  font-weight: 500
</style>
