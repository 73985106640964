<template>
  <g>
    <text x="400" :y="figures.textY" v-if="isInFullScreen" class="scheme-text">
      № {{ currentNumber }} |
      <tspan font-weight="bold">
        {{ item.material.name }}
      </tspan>
      | {{ item.material.value }} {{ $t('message.units.mm') }}
    </text>
    <pattern :id="bgId" width="1" height="1">
      <image :href="image.url" x="0" y="0" width="336" :transform="`scale(1 ${coefficient})`" />
    </pattern>
    <path
      :d="figures.hexagon"
      class="hexagon__image"
      :content="`№ ${currentNumber} | <span class='hexagon__tooltip'><strong>${
        item.material.name
      }</strong><span class='hexagon__tooltip-info'> | ${item.material.value}${$t(
        'message.units.mm'
      )}</span></span>`"
      v-tippy="{
        followCursor: false,
        animation: 'shift-toward',
        theme: 'light',
        delay: 100,
        arrow: !isInMobileModal,
        placement: isInMobileModal ? 'top bottom' : 'left'
      }"
      :fill="`url(#${bgId})`"
    >
    </path>
    <slot name="fastener" v-if="isLayerHasFastener"></slot>
  </g>
</template>

<script>
export default {
  props: {
    figures: Object,
    item: Object,
    currentNumber: Number,
    isInMobileModal: Boolean,
    coefficient: Number,
    isLayerHasFastener: Boolean,
    image: Object,
    isInFullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgId() {
      return this.isInMobileModal
        ? `bg--mobile-${this.currentNumber}`
        : `bg--desktop-${this.currentNumber}`
    }
  }
}
</script>

<style scoped></style>
