<template>
  <div class="fastener-toggler">
    <input
      type="checkbox"
      class="fastener-toggler__input"
      name="fastenerInfo"
      @change="showFastenerInfo"
      :checked="currentChecked === id"
      :id="id"
      :value="id"
    />
    <label :for="id" class="fastener-toggler__label">
      <inline-svg :src="require('@/assets/img/icons/fastener--toggler.svg')" />
    </label>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  props: {
    id: String,
    currentChecked: String
  },
  components: {
    InlineSvg
  },
  methods: {
    showFastenerInfo() {
      this.$emit('showFastenerInfo', this.currentChecked === this.id ? '' : this.id)
    }
  }
}
</script>

<style scoped lang="sass">
.fastener-toggler
  width: 100%
  height: 100%
  display: flex
  align-items: center
  &__input
    position: absolute
    display: none
    pointer-events: none
    opacity: 0
    &:checked
      + label
          // color: $black
          background: transparent
  &__label
    height: rem(24)
    width: rem(24)
    background: $cool_B
    border-radius: rem(4)
    transition: .5s
    color: $red
    cursor: pointer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    svg
      path
        transition: .5s
</style>
