var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{staticClass:"layers__group-item"},[(_vm.isInFullScreen)?_c('text',{staticClass:"scheme-text",attrs:{"x":"400","y":_vm.figures.textY}},[_vm._v(" № "+_vm._s(_vm.currentNumber)+" | "),_c('tspan',{attrs:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.item.material.name)+" ")]),_vm._v(" | "+_vm._s(_vm.item.material.value)+" "+_vm._s(_vm.$t('message.units.mm'))+" ")]):_vm._e(),_c('path',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      followCursor: false,
      animation: 'shift-toward',
      theme: 'light',
      delay: 100,
      arrow: !_vm.isInMobileModal,
      placement: _vm.isInMobileModal ? 'top bottom' : 'left'
    }),expression:"{\n      followCursor: false,\n      animation: 'shift-toward',\n      theme: 'light',\n      delay: 100,\n      arrow: !isInMobileModal,\n      placement: isInMobileModal ? 'top bottom' : 'left'\n    }"}],staticClass:"hexagon",attrs:{"d":_vm.figures.hexagon,"content":("<span class='hexagon__tooltip'>№ " + _vm.currentNumber + " | <strong>" + (_vm.item.material.name) + "</strong><span class='hexagon__tooltip-info'> | " + (_vm.item.material.value) + (_vm.$t(
      'message.units.mm'
    )) + "</span></span>"),"fill":_vm.color[0].color}}),_c('path',{staticClass:"texture",attrs:{"d":_vm.figures.hexagon,"fill":("url(#" + _vm.patternId + ")")}}),(_vm.isDoubleLayer)?_c('path',{staticClass:"hexagon__top",attrs:{"d":_vm.figures.hexagonTop,"fill":_vm.color[1].color}}):_vm._e(),(_vm.isDoubleLayer)?_c('path',{staticClass:"texture",attrs:{"d":_vm.figures.hexagonTop,"fill":("url(#" + _vm.patternId + "--top)")}}):_vm._e(),_c('path',{staticClass:"circuit",attrs:{"d":_vm.figures.circuit,"stroke-width":"2","stroke":"white","fill":"none","stroke-opacity":"0.4"}}),_c('path',{staticClass:"polygon",attrs:{"opacity":"0.15","d":_vm.figures.polygonLeft,"fill":"black"}}),_c('path',{staticClass:"polygon",attrs:{"opacity":"0.15","d":_vm.figures.polygonRight,"fill":"white"}}),_c('path',{staticClass:"polygon",attrs:{"d":_vm.figures.polygonTop,"fill":("url(" + _vm.gradientId + ")")}}),(_vm.isLayerHasFastener)?_vm._t("fastener"):_vm._e(),_c('defs',[_c('pattern',{attrs:{"id":_vm.patternId,"x":"0","y":"0","width":"128","height":"128","patternUnits":"userSpaceOnUse"}},[_c('image',{attrs:{"href":_vm.color[0].pattern,"x":"0","y":"0","height":"128","width":"128"}})])]),(_vm.isDoubleLayer)?_c('defs',[_c('pattern',{attrs:{"id":(_vm.patternId + "--top"),"x":"0","y":"0","width":"128","height":"128","patternUnits":"userSpaceOnUse"}},[_c('image',{attrs:{"href":_vm.color[1].pattern,"x":"0","y":"0","height":"128","width":"128"}})])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }