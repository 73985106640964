<template>
  <div :class="wrapperClasses">
    <layer-selection
      v-if="selectedMaterial"
      :founded-layers="foundedLayers"
      :founded-parent-layers="foundedParentLayers"
      :search-results="searchResults"
      :selected-material="selectedMaterial"
      :layer-id="layerId"
      :layer-index="layerIndex"
      :new-parent-layer="newParentLayer"
      :type="type"
      @setParentLayer="setParentLayer"
      @dismissSelectedMaterial="dismissSelectedMaterial"
      @hideList="hideList"
    />
    <template v-else>
      <search-input
        @updateFocusStatus="updateFocusStatus"
        :searchQuery="searchQuery"
        @search="updateSearchQuery"
      />
      <transition name="fade" mode="out-in">
        <hint v-if="isShowHint" />
        <search-results
          :search-results="searchResults"
          v-if="isShowSearchResults"
          :search-query="searchQuery"
          :layer-id="layerId"
          @selectMaterial="getLayersByMaterial"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import { searchMaterial, getLayersListByMaterial } from '@/api'
import SearchInput from '@/components/smart/MaterialsSearch/SearchInput'
import { debounce } from 'lodash'
import Hint from '@/components/smart/MaterialsSearch/Hint'
import SearchResults from '@/components/smart/MaterialsSearch/SearchResults'
import LayerSelection from '@/components/smart/MaterialsSearch/LayerSelection'
export default {
  props: {
    isParentLayerSearch: {
      type: Boolean
    },
    layerId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    layerIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isInputFocused: false,
    searchQuery: '',
    searchResults: [],
    isShowResults: false,
    foundedLayers: null,
    foundedParentLayers: null,
    selectedMaterial: null,
    isLoading: false,
    newParentLayer: null
  }),
  name: 'MaterialsSearch',
  components: { LayerSelection, SearchResults, Hint, SearchInput },
  watch: {
    searchQuery() {
      this.deboucedMakeSearch()
    }
  },
  methods: {
    updateFocusStatus(val) {
      this.isInputFocused = val
    },
    updateSearchQuery(val) {
      // console.log(val)
      this.searchQuery = val
    },
    makeSearch() {
      if (this.foundedLayers) {
        this.foundedLayers = null
      }
      if (this.foundedParentLayers) {
        this.foundedParentLayers = null
      }
      if (this.isSearchQueryValidLength) {
        this.isLoading = true
        searchMaterial({
          type: this.type,
          lang: this.$i18n.locale,
          query: this.searchQuery,
          layerId: this.layerId
        }).then(response => {
          this.dismissSelectedMaterial()
          this.isLoading = false
          this.isShowResults = true
          this.searchResults = response.data
        })
      } else {
        this.isShowResults = false
        this.searchResults = []
      }
    },
    getLayersByMaterial(materialId) {
      this.selectedMaterial = materialId
      getLayersListByMaterial({
        lang: this.$i18n.locale,
        layerId: this.layerId,
        type: this.type,
        materialId
      }).then(response => {
        if (this.layerId !== null) {
          this.foundedLayers = response.data
        } else {
          this.foundedParentLayers = response.data
        }
      })
    },
    dismissSelectedMaterial() {
      this.selectedMaterial = null
      this.foundedLayers = null
      this.foundedParentLayers = null
      this.newParentLayer = null
      this.$emit('setParentLayerId', null)
    },
    setParentLayer(newParentLayer) {
      this.newParentLayer = newParentLayer
      this.$emit('setParentLayerId', newParentLayer.id)
      this.$nextTick().then(() => this.getLayersByMaterial(this.selectedMaterial))
    },
    hideList() {
      // this.dismissSelectedMaterial()
      // this.searchQuery = ''
      // this.searchResults = []
      // this.isShowResults = false
      this.$emit('setParentLayerId', null)
      this.$emit('hideList')
    }
  },
  created() {
    this.deboucedMakeSearch = debounce(this.makeSearch, 500)
  },
  computed: {
    isShowHint() {
      return this.searchQuery.length < 4 && this.isInputFocused
    },
    isSearchQueryValidLength() {
      return this.searchQuery.length > 3
    },
    isShowSearchResults() {
      return this.isShowResults && this.isSearchQueryValidLength
    },
    wrapperClasses() {
      return {
        'search-box': true,
        'search-box--is-loading': this.isLoading,
        'search-box--divided': this.isShowSearchResults
      }
    }
  }
}
</script>

<style scoped lang="sass">
.search-box
  margin-bottom: rem(16)
  position: relative
  // padding-bottom: rem(16)
  &--is-loading
    &:before
      animation: loading .5s linear
  &--divided
    box-shadow: inset 0 rem(-1) 0 $red
    padding-bottom: rem(16)
  &:before
    content: ""
    position: absolute
    +media((left: (0: 0, 576: rem(20))))
    top: 0
    +media((width: (0: 100%, 576: calc(100% - 40px))))
    height: rem(1)
    opacity: 0
    background: $red
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.fade-enter-active
  transition: all .3s ease
.fade-leave-active
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.fade-enter, .fade-leave-to
  transform: translateY(-10px)
  opacity: 0
@keyframes loading
  0%
    width: 0
    opacity: 1
  100%
    +media((width: (0: 100%, 576: calc(100% - 40px))))
</style>
