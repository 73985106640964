<template>
  <div class="layer-selection">
    <search-results-item
      :item="originalSelectedMaterial"
      :is-in-search-results="false"
      @dismissSelectedMaterial="dismissSelectedMaterial"
    />
    <search-loading :is-loading-show="isLoadingShow" v-if="isLoadingShow" />
    <template v-else>
      <template v-if="isParentLayerErrorShow">
        <div class="search-results__error">
          {{ $t('message.system.search.parentLayerError') }}
        </div>
      </template>
      <template v-else>
        <layer-selection-items
          v-if="isSearchChildLayers"
          :founded-parent-layers="foundedParentLayers"
          :founded-layers="foundedLayers"
          :layer-id="layerId"
          :type="type"
          @addLayer="addChildLayer"
        >
          {{ $t('message.system.search.selectLayer') }}
        </layer-selection-items>
        <layer-selection-items
          v-else
          :founded-parent-layers="foundedParentLayers"
          :founded-layers="foundedParentLayers"
          :layer-id="layerId"
          :type="type"
          @addLayer="addParentLayer"
        >
          {{ $t('message.system.search.selectParentLayer') }}
        </layer-selection-items>
      </template>
    </template>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { mapMutations } from 'vuex'
import SearchResultsItem from '@/components/smart/MaterialsSearch/SearchResultsItem'
import LayerSelectionItems from '@/components/smart/MaterialsSearch/LayerSelectionItems'
import SearchLoading from '@/components/smart/MaterialsSearch/SearchLoading'
export default {
  components: { SearchLoading, LayerSelectionItems, SearchResultsItem },
  props: {
    foundedLayers: {
      type: Array,
      default: () => []
    },
    foundedParentLayers: {
      type: Array,
      default: () => []
    },
    selectedMaterial: {
      type: Number,
      required: true
    },
    searchResults: {
      type: Array,
      required: true
    },
    layerId: {
      type: Number,
      default: null
    },
    layerIndex: {
      type: Number,
      required: true
    },
    newParentLayer: {
      type: Object,
      default: () => ({})
    },
    type: String
  },
  name: 'LayerSelection',
  methods: {
    ...mapMutations({
      ADD_PARENT_LAYER_TO_SYSTEM_FROM_SEARCH: 'ADD_PARENT_LAYER_TO_SYSTEM_FROM_SEARCH',
      ADD_CHILD_LAYER_TO_JUNCTION_FROM_SEARCH: 'ADD_CHILD_LAYER_TO_JUNCTION_FROM_SEARCH',
      ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH: 'ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH',
      ADD_PARENT_LAYER_TO_JUNCTION_FROM_SEARCH: 'ADD_PARENT_LAYER_TO_JUNCTION_FROM_SEARCH'
    }),
    dismissSelectedMaterial() {
      this.$emit('dismissSelectedMaterial')
    },
    addChildLayer(childLayer) {
      const newChildLayer = {
        ...childLayer,
        material: this.originalSelectedMaterial.material
      }
      if (this.isNeedToAddChild) {
        this[`ADD_CHILD_LAYER_TO_${this.type.toUpperCase()}_FROM_SEARCH`]({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          junctionIndex: this.junctionIndex,
          childLayer: newChildLayer
        })
      } else {
        const completedNewParentLayer = cloneDeep(this.newParentLayer)
        completedNewParentLayer.items = [{ ...newChildLayer }]
        this[`ADD_PARENT_LAYER_TO_${this.type.toUpperCase()}_FROM_SEARCH`]({
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          newParentLayer: completedNewParentLayer
        })
      }
      this.$emit('hideList')
    },
    addParentLayer(parentLayer) {
      this.$emit('setParentLayer', cloneDeep(parentLayer))
    }
  },
  computed: {
    originalSelectedMaterial() {
      return this.searchResults.find(material => material.id === this.selectedMaterial)
    },
    isLoadingShow() {
      return this.isSearchChildLayers ? !this.foundedLayers : !this.foundedParentLayers
    },
    isParentLayerErrorShow() {
      return this.isSearchChildLayers
        ? false
        : this.foundedParentLayers && !this.foundedParentLayers.length
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    isSearchChildLayers() {
      return this.layerId !== null
    },
    isNeedToAddChild() {
      return isEmpty(this.newParentLayer)
    }
  }
}
</script>

<style scoped lang="sass">
.search-results
  &__error
    +media((margin: (576: rem(-16) 0 0)))
    padding: 0 rem(20)
    @extend .fs-12
    color: $dark-grey
</style>
