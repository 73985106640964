<template>
  <div
    class="search-results__item"
    :class="{
      'search-results__item--no-interaction': !isInSearchResults
    }"
    @click="handler"
  >
    <img :src="item.image" :alt="item.name" class="picture" />
    <div class="name">
      {{ item.name }}
    </div>
    <simple-button @clickedFromSimpleBtn="dismiss" no-radius v-if="!isInSearchResults">
      <template #icon>
        <img src="@/assets/img/icons/layer--add-close--big.svg" alt="❌" />
      </template>
    </simple-button>
  </div>
</template>

<script>
import SimpleButton from '@/components/elements/Dom/Simple-button'
export default {
  components: { SimpleButton },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isInSearchResults: {
      type: Boolean,
      default: true
    }
  },
  name: 'SearchResultsItem',
  methods: {
    handler() {
      if (this.isInSearchResults) {
        this.$emit('click')
      }
    },
    dismiss() {
      this.$emit('dismissSelectedMaterial')
    }
  }
}
</script>

<style scoped lang="sass">
.search-results
  &__item
    display: grid
    $t-l: 4fr 1fr 16fr
    $t-m: rem(70) rem(25) 1fr
    $t-s: rem(70) 1fr 16fr
    +media((grid-template-columns: (0: $t-s, 576: $t-m, 960: $t-l)))
    align-items: center
    padding: rem(8) 0
    transition: .5s
    &:not(.search-results__item--no-interaction)
      @media(any-hover: hover)
        &:hover
          background: $red
          box-shadow: none
          .name
            color: $white
    &--no-interaction
      grid-template-columns: 4fr 1fr 14fr rem(40)
      $t-l: 4fr 1fr 14fr rem(40)
      $t-m: rem(70) rem(25) 1fr rem(40)
      $t-s: rem(70) 1fr 14fr rem(30)
      +media((grid-template-columns: (0: $t-s, 576: $t-m, 960: $t-l)))
      +media((max-width: (576: calc(100% - 40px))))
      +media((margin: (0: 0 auto rem(16), 576: 0 auto rem(32))))
      .name
        padding-right: rem(10)
    &:not(.search-results__item--no-interaction)
      cursor: pointer
    &:not(:last-child)
      box-shadow: inset 0 rem(-1) 0 $cool_B
    .picture
      width: 100%
      max-width: rem(70)
      display: block
      margin: auto
      height: auto
    .name
      transition: .5s
      grid-column-start: 3
      font-weight: 500
      @extend .fs-12
    .simple-btn
      padding: 0
      background: transparent
</style>
